import React from "react"

import "./index.css"
// import "../../public/js/main.js"

import quoteData from "../data/quotes"
import SocialIcons from "../components/social-icons"

export default function Home() {
  function putRandomQuote() {
    let quotes = quoteData

    let randomQuote = quotes[Math.floor(Math.random() * quotes.length)]

    return randomQuote
  }

  let year = new Date().getFullYear() || 2020

  return (
    <div id="root">
      <div id="main">
        <div id="left">
          <img
            src="assets/rohan.jpg"
            alt="Rohan"
            className="noselect"
            draggable="false"
          />
        </div>

        <div className="divider"></div>

        <div id="right">
          <h1 className="noselect">Rohan Tinna</h1>
          <a id="mail" href="mailto:rohan@rohantinna.com" className="noselect">
            <div>
              <i className="fas fa-envelope"></i>
              <span>rohan@rohantinna.com</span>
            </div>
          </a>

          <p>
            Hi 👋🏼 I'm Rohan, an engineer and investor! Currently, I'm studying LLMs. You can reach out to me on X or
            email. Once in a while, I post productive stuff on my{" "}
            <a href="/blog" className="link">
              blog
            </a>
            .
          </p>

          <SocialIcons />

          {/* 
        
        // Resume Button

        <div id="resume-btn">
          <a href="/" className="button noselect">
            <div>
              <i className="fas fa-file"></i>
              <span>Download Resumé</span>
            </div>
          </a>
        </div>
        
        */}
        </div>
      </div>

      <span id="copyright" className="noselect">
        &copy; Copyright {year}.
      </span>

      <div id="right-click" className="noselect">
        <h3>
          {/* <pre> */}
          {/* Made with <span className="heart">&hearts;</span> by Rohan */}
          {"“"}
          {putRandomQuote()}
          {"”"}
          {/* </pre> */}
        </h3>
        <p>
          &copy; Copyright {year}. <a href="/changelog">Version 4.0.0</a>.
        </p>
      </div>
    </div>
  )
}
